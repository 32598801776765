<template>
	<div class="dashboard">
		<div v-if="null != user && isAdvertiser(user) && isProfileLocked(user)" class="dashboard__advertiser">
			<BannerWindow :slug="[ADVERTISER_DASHBOARD_BANNER_SLUG]" />
			<HolidayEventBanner v-if="IS_PROMO_ACTIVE" />
			<div class="dashboard__advertiser-wrapper">
				<DashboardToDoActions />
			</div>
			<div class="dashboard__advertiser-wrapper">
				<div class="dashboard__publisher-title dashboard__publisher-title--action" @click="goToActiveCarts">{{ $t('dashboard.carts') }}</div>
				<OrderBasketListTable />
			</div>
			<div class="dashboard__advertiser-wrapper">
				<div class="dashboard__publisher-title dashboard__publisher-title--action" @click="goToArticles">{{ $t('dashboard.articles') }}</div>
				<ArticleListTable />
			</div>
			<div class="dashboard__advertiser-wrapper">
				<div class="dashboard__publisher-title dashboard__publisher-title--action" @click="goToOrders">{{ $t('dashboard.orders') }}</div>
				<OrderListTable />
			</div>
			<DashboardNews />
		</div>
		
		<div v-if="null != user && isPublisher(user)" class="dashboard__publisher">
			<BannerWindow :slug="[PUBLISHER_DASHBOARD_BANNER_SLUG]" />
			<div class="dashboard__publisher-wrapper">
				<DashboardToDoActions />
			</div>
			<div class="dashboard__publisher-wrapper">
				<div class="dashboard__publisher-title dashboard__publisher-title--action" @click="goToOfferList">{{ $t('dashboard.bestOffers') }}</div>
				<PublisherStatisticsBestOffers :items="bestOffersItems" :loading="bestOffersLoading" />
			</div>
			<div class="dashboard__publisher-wrapper dashboard__publisher-wrapper--orders">
				<PublisherOrders
					:loading="publisherOrderLoading"
					:rejected-counter="publisherOrderRejectedStatus"
					:reports-counter="publisherOrderNotesStatus"
					:to-approve-counter="publisherOrderApproveStatus"
				/>
			</div>
			<div class="dashboard__publisher-wrapper dashboard__publisher-wrapper--to-do">
				<StatisticsPublisher @orderCountStats="orderCountStats" @bestOffers="handleBestOffers" @statistics="handleStatisticsCountersOffers" />
			</div>
			<div>
				<div class="dashboard__publisher-title">{{ $t('dashboard.statistics') }}</div>
				<div class="dashboard__counter-wrapper">
					<PublisherStatisticsCounterWindow :statistic-name="CLIENTS_COUNTER" :items="statisticsCounterClientsItems" :loading="statisticsLoading" />
					<PublisherStatisticsCounterWindow :statistic-name="ORDER_COUNTER" :items="statisticsCounterOrderItems" :loading="statisticsLoading" />
					<PublisherStatisticsCounterWindow :statistic-name="SALARY_COUNTER" :items="statisticsCounterSalaryItems" :loading="statisticsLoading" />
					<PublisherStatisticsCounterWindow :statistic-name="AVERAGE_SALARY_COUNTER" :items="statisticsCounterAverageSalaryItems" :loading="statisticsLoading" />
					<PublisherStatisticsCounterWindow :statistic-name="DOMAINS_COUNTER" :items="statisticsCounterDomainsItems" :loading="statisticsLoading" />
				</div>
			</div>
			<DashboardNews />
		</div>
		
		<div v-if="null != user && isModerator(user)" class="dashboard-moderator">
			<div class="dashboard-moderator__wrapper">
				<ModeratorCounter icon="articles" :title="$t('dashboard.counterArticleAfterDeadline')" :counter="articleAfterDeadlineCounter" />
				<ModeratorCounter icon="projects" :title="$t('dashboard.counterPublication')" :counter="publicationOrderCounter" />
				<ModeratorCounter icon="projects" :title="$t('dashboard.counterPublicationAfterDeadline')" :counter="publicationAfterDeadCounter" />
				<ModeratorCounter icon="websites" :title="$t('dashboard.counterWebsite')" :counter="websitesCounter" />
				<ModeratorCounter icon="websites" :title="$t('dashboard.counterWebsiteOffer')" :counter="websitesOfferCounter" />
				<ModeratorCounter icon="chat" :title="$t('dashboard.counterChats')" :counter="chatsCounter" />
				<ModeratorCounter icon="articles" :title="$t('dashboard.websiteRequests')" :counter="websiteRequestCounter" />
				<ModeratorCounter icon="stats" :title="$t('dashboard.ahrefsLimitsUsage')" :counter="ahrefsLimitsCounter" :second-counter="ahrefsLimitsMaxCounter" percent />
			</div>
		</div>
		
		<div v-if="null != user && isContentWriter(user)">
			<ContentWriterCounter />
		</div>
		
		<div v-if="null != user && isAdmin(user)">
			<AdminCounter />
		</div>
    </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { storeToRefs } from 'pinia';
import router from '@/router';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { isModerator, isPublisher, isAdvertiser, isContentWriter, isAdmin } from '@/hooks/UserHooks';
import { isProfileLocked } from '@/hooks/ProfileHooks';
import { ADVERTISER_DASHBOARD_BANNER_SLUG, PUBLISHER_DASHBOARD_BANNER_SLUG } from '@/hooks/BannersHooks';
import { ROUTE_DASHBOARD } from '@/hooks/RoutingHooks';
import { IS_PROMO_ACTIVE } from '@/hooks/PromoHooks';
import dayjs from 'dayjs';
import DashboardApi from '@/api/v1/DashboardApi';
import AhrefsApi from '@/apiWebsites/v1/AhrefsApi';
import {
	AVERAGE_SALARY_COUNTER,
	CLIENTS_COUNTER,
	DOMAINS_COUNTER,
	ORDER_COUNTER,
	SALARY_COUNTER
} from '@/hooks/StatisticsHook';
import type {
	DashboardPublisherInterface,
	IDashboardBestOffers,
	IDashboardCounterStatistics
} from '@/models/DashboardPublisherModel';
import BannerWindow from '@/views/Dashboard/components/BannerWindow.vue';
import OrderListTable from '@/components/organisms/Orders/OrderListTable.vue';
import StatisticsPublisher from '@/views/Dashboard/components/StatisticsPublisher.vue';
import DashboardNews from '@/views/Dashboard/components/DashboardNews.vue';
import DashboardToDoActions from '@/views/Dashboard/components/DashboardToDoActions.vue';
import ModeratorCounter from '@/views/Dashboard/components/ModeratorCounter.vue';
import ContentWriterCounter from '@/views/Dashboard/components/ContentWriterCounter.vue';
import AdminCounter from '@/views/Dashboard/components/AdminCounter.vue';
import PublisherOrders from '@/views/Dashboard/components/PublisherOrders.vue';
import ArticleListTable from '@/components/organisms/Articles/ArticleListTable.vue';
import PublisherStatisticsBestOffers from '@/views/Dashboard/components/PublisherStatisticsBestOffers.vue';
import PublisherStatisticsCounterWindow from '@/views/Dashboard/components/PublisherStatisticsCounterWindow.vue';
import OrderBasketListTable from '@/components/organisms/Orders/OrderBasketListTable.vue';
import HolidayEventBanner from '@/components/rare/HolidayEventBanner.vue';

const { user } = storeToRefs(useUserStore());
const { t } = useI18n();

const dashboardApi = new DashboardApi();
const ahrefsApi = new AhrefsApi();

const articleAfterDeadlineCounter = ref<number>(0);
const publicationOrderCounter = ref<number>(0);
const publicationAfterDeadCounter = ref<number>(0);
const websitesCounter = ref<number>(0);
const websitesOfferCounter = ref<number>(0);
const chatsCounter = ref<number>(0);
const websiteRequestCounter = ref<number>(0);
const ahrefsLimitsCounter = ref<number>(0);
const ahrefsLimitsMaxCounter = ref<number>(0);
const publisherOrderLoading = ref<boolean>(false);
const publisherOrderRejectedStatus = ref<number>(0);
const publisherOrderNotesStatus = ref<number>(0);
const publisherOrderApproveStatus = ref<number>(0);

const isModeratorDashboard = ref<boolean>(false);
if (null != user.value && isModerator(user.value) && router.currentRoute.value.name === ROUTE_DASHBOARD) {
	isModeratorDashboard.value = true;
}
const getModeratorCounters = async() => {
	try {
		const result = await dashboardApi.moderatorCountersList();
		if (!result.isSuccess) {
			return;
		}
		articleAfterDeadlineCounter.value = result.payload.articles_after_deadline_count;
		publicationOrderCounter.value = result.payload.publication_orders_count;
		publicationOrderCounter.value = result.payload.publication_after_deadline_count;
		websitesCounter.value = result.payload.websites_count;
		websitesOfferCounter.value = result.payload.website_offers_count;
		chatsCounter.value = result.payload.notes_count;
		websiteRequestCounter.value = result.payload.website_requests_count;
	} catch (e) {
		return;
	}
};

const ahrefsLimitsList = async() => {
	const year = +dayjs().format('YYYY-MM-DD').split('-')[0];
	const month = +dayjs().format('YYYY-MM-DD').split('-')[1];
	try {
		const result = await ahrefsApi.ahrefsLimitList(1, 10, year, month);
		if (!result.isSuccess) {
			toast.error(`${t('ahrefs.toast.errorAhrefsList')}`);
			return;
		}
		ahrefsLimitsCounter.value = result.payload.last_limits_and_usage[0].units_usage_workspace;
		ahrefsLimitsMaxCounter.value = result.payload.last_limits_and_usage[0].units_limit_workspace;
	} catch (e) {
		toast.error(`${t('ahrefs.toast.errorAhrefsList')}`);
		return;
	}
};
if (isModeratorDashboard.value) {
	getModeratorCounters();
	ahrefsLimitsList();
}

const orderCountStats = (orderCountLoading:boolean, stats:DashboardPublisherInterface|null) => {
	publisherOrderLoading.value = orderCountLoading;
	
	if (null != stats) {
		publisherOrderRejectedStatus.value = stats.rejected_orders;
		publisherOrderNotesStatus.value = stats.notes_orders;
		publisherOrderApproveStatus.value = stats.to_approve_orders;
	}
};

const goToActiveCarts = () => {
	router.push(`/order/baskets/${user.value?.id}`);
};

const goToArticles = () => {
	router.push('/article');
};

const goToOrders = () => {
	router.push('/order/publication');
};

const goToOfferList = () => {
	router.push('/websites/offers');
};

const bestOffersItems = ref<Array<IDashboardBestOffers>|any>(null);
const bestOffersLoading = ref<boolean>(false);
const handleBestOffers = (bestOffers: Array<IDashboardBestOffers>|any, loader: boolean) => {
	bestOffersItems.value = bestOffers;
	bestOffersLoading.value = loader;
};
const statisticsCounterClientsItems = ref<IDashboardCounterStatistics|null>(null);
const statisticsCounterOrderItems = ref<IDashboardCounterStatistics|null>(null);
const statisticsCounterSalaryItems = ref<IDashboardCounterStatistics|null>(null);
const statisticsCounterAverageSalaryItems = ref<IDashboardCounterStatistics|null>(null);
const statisticsCounterDomainsItems = ref<IDashboardCounterStatistics|null>(null);
const statisticsLoading = ref<boolean>(false);
const handleStatisticsCountersOffers = (
	clientItems: IDashboardCounterStatistics|null,
	orderItems: IDashboardCounterStatistics|null,
	salaryItems: IDashboardCounterStatistics|null,
	averageItems: IDashboardCounterStatistics|null,
	domainItems: IDashboardCounterStatistics|null,
	loader: boolean
) => {
	statisticsCounterClientsItems.value = clientItems;
	statisticsCounterOrderItems.value = orderItems;
	statisticsCounterSalaryItems.value = salaryItems;
	statisticsCounterAverageSalaryItems.value = averageItems;
	statisticsCounterDomainsItems.value = domainItems;
	statisticsLoading.value = loader;
};
</script>

<style scoped lang="scss">
.dashboard {
	&__publisher-title {
		font-size: 24px;
		font-weight: 700;
		color: $primary-400;
		margin-bottom: 20px;
		
		&--action {
			width: max-content;
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&-moderator {
		display: flex;
		flex-direction: column;
		
		&__wrapper {
			display: flex;
			gap: 25px;
			flex-wrap: wrap;
		}
	}
	
	&__heading-label {
		margin-bottom: 15px;
	}
	
	&__advertiser-modal-wrapper {
		img {
			display: flex;
			width: max-content;
			margin: 10px auto;
		}
	}
	
	&__publisher {
		display: flex;
		flex-wrap: wrap;
		gap: 40px;
		margin-top: -40px;
	}
	
	&__publisher-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(xxl) {
			width: calc(50% - 20px);
		}
		
		&--to-do {
			@include media-breakpoint-up(xxl) {
				width: calc(75% - 40px);
			}
		}
		
		&--orders {
			@include media-breakpoint-up(xxl) {
				width: 25%;
			}
		}
	}
	
	&__advertiser {
		display: flex;
		flex-wrap: wrap;
		gap: 40px;
		margin-top: -40px;
	}
	
	&__advertiser-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(xxl) {
			width: calc(50% - 20px);
		}
	}
	
	&__counter-wrapper {
		margin-top: 24px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
			gap: 40px;
			flex-wrap: wrap;
		}
	}
}
</style>
