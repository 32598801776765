<template>
	<div class="order">
		<div class="order__title-wrapper">
			<h1 class="order__title">{{ $t('order.orderFlow.order') }}
				<div v-if="null != user && isModerator(user)" class="order__origin-status">{{ $t('order.orderFlow.originStatus') }}: {{ currentStatus }}</div>
			</h1>
			<div class="order__quick-accept-btn-wrapper">
				<MButton
					v-if="isQuickAction"
					id="order-quick-accept"
					:label="quickAcceptBtnTitle"
					normal
					blue400
					@click="quickAcceptAction"
				/>
			</div>
		</div>
		
		<div class="order__wrapper">
			<div class="order__left-wrapper">
				<MExpansionPanel id="article-details-panel" v-model="expandArticleDetailsPanel" :title-bg-color="!isArticlePanelDetailsDisabled ? white : grey300" :content-bg-color="white" :disabled="isArticlePanelDetailsDisabled" :loading="loading">
					<template v-slot:title>
						<div class="order__subtitle">{{ $t('order.orderFlow.articleDetails') }}</div>
					</template>
					<template v-slot:text>
						<div class="order__article-details-wrapper">
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.campaign') }}</div>
								<div class="order__bold">{{ articleDetails?.campaign }}</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.language') }}</div>
								<div class="order__bold">{{ changeLanguageIdToName(articleDetails?.language_id) }}</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.articleTopic') }}</div>
								<div class="order__bold order__long-text">{{ articleDetails.title }}</div>
							</div>
							<div v-if="null != articleDetails.type" class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.articleType') }}</div>
								<div class="order__bold">{{ articleDetails.type }}</div>
							</div>
							<div v-if="null != articleDetails.links" class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.linksInArticle') }}</div>
								<div class="order__bold" v-html="articleDetails.links" />
							</div>
							<div class="order__size-14">{{ $t('order.orderFlow.reports') }}</div>
							<div class="order__bold" v-html="articleDetails.comments" />
							<div class="order__bold" v-html="articleDetails.reports" />
						</div>
					</template>
				</MExpansionPanel>
				
				<MExpansionPanel id="article-panel" v-model="expandArticlePanel" :title-bg-color="!isArticlePanelDisabled ? white : grey300" :content-bg-color="white" :disabled="isArticlePanelDisabled" :loading="loading">
					<template v-slot:title>
						<div class="order__subtitle">{{ $t('order.orderFlow.article') }}</div>
					</template>
					<template v-slot:text>
						<div class="order__edit-mode-wrapper">
							<MButton v-if="null != user && isModerator(user) && currentStatus !== CONTENT_IN_PROGRESS_STATUS" :label="isModEditArticle ? $t('order.orderFlow.flowMode') : $t('order.orderFlow.editMode')" blue400 normal @click="editArticle" />
						</div>
						<div v-if="checkArticleFormAccess()">
							<Form :validation-schema="schema" @submit="onSubmit">
								<MTextField
									id="order-article-title"
									name="articleTitle"
									v-model="articleForm.title"
									label-text-required
									:separate-label="$t('order.orderFlow.articleTitle')"
									:placeholder="$t('order.orderFlow.articleTitlePlaceholder')"
									clearable
								/>
								<MVueEditor
									id="order-article-content"
									name="articleContent"
									v-model="articleForm.content"
									label-text-required
									:separate-label="$t('order.orderFlow.articleContent')"
									:placeholder="$t('order.orderFlow.articleContentPlaceholder')"
								/>
								<div>{{ $t('order.orderFlow.maxMBSize') }}: {{ articleSize }} MB/19.66 MB</div>
								<MTextArea
									v-if="null != user && !isPublisher(user)"
									id="order-article-publisher-note"
									name="articlePublisherNote"
									v-model="articleForm.publisherNote"
									:separate-label="$t('order.orderFlow.articlePublisherNote')"
									:placeholder="$t('order.orderFlow.articlePublisherNotePlaceholder')"
								/>
								<div class="order__label-required">{{ $t('order.orderFlow.addPhoto') }} <span>{{ $t('components.fieldRequired') }}</span></div>
								<div v-if="0 === articleDetails.image.length || null == articleDetails.image" class="order__drop-wrapper" :class="{'order__drop-wrapper--error': isFileError}">
									<div @dragover="dragover" @dragleave="dragleave" @drop="drop">
										<div class="order__drop-wrapper--input-wrapper">
											<label for="assetsFieldHandle">
												<div class="order__drop-text">{{ $t('order.orderFlow.uploadDropMessage') }}</div>
												<div class="order__remove-wrapper">
													<div v-if="articleForm.file !== null">
														{{ articleForm.file.name }}
													</div>
													<div v-if="articleForm.file !== null" @click="removeFile">
														<MIcon icon="close" width="12" height="12" />
													</div>
												</div>
											</label>
											<input
												type="file"
												name="orderPhoto"
												id="order-article-photo"
												@change="onChangeImage"
												ref="file"
												accept=".jpg,.jpeg,.png"
											/>
											<div class="order__file-btn">{{ $t('order.orderFlow.selectFile') }}</div>
										</div>
									</div>
								</div>
								<div v-if="0 !== articleDetails.image.length && null != articleDetails.image">
									<div class="order__photo-wrapper">
										<img :src="articleDetails.image[0].url" alt="marketin9">
										<div class="order__photo-btn-wrapper">
											<div class="order__edit-btn-wrapper">
												<div id="edit-photo" class="order__edit-photo">
													<div class="order__btn">{{ $t('articles.edit') }}</div>
													<div class="order__upload-hover-wrapper">
														<v-file-input v-model="articleForm.file" accept="image/*" />
													</div>
												</div>
											</div>
											<div id="remove-photo" class="order__btn order__btn--red" @click="deleteImage">{{ $t('articles.delete') }}</div>
										</div>
									</div>
								</div>
								<div v-if="isFileError && 0 === articleDetails.image.length" class="order__error-message">{{ $t('order.orderFlow.validation.photoRequired') }}</div>
							</Form>
							<div class="order__article-btn-wrapper">
								<MButton id="order-article-preview" :label="$t('order.orderFlow.articlePreview')" transparent-with-border normal @click="goToArticlePreview(articleDetails.uuid)" />
								<div class="order__article-btn-second-wrapper">
									<MButton id="order-article-save" :label="$t('order.orderFlow.saveArticle')" blue400 normal @click="onSubmit(false)" :loading="loading" :loading-color="white" />
									<MButton v-if="null != user && ((isPublisher(user) && currentStatus !== CONTENT_DISAPPROVED_STATUS) || isContentWriter(user) || (isModerator(user) && !isModEditArticle))" id="order-article-report-comments" :label="$t('order.orderFlow.reportComments')" blue400 normal @click="openReportDialog" />
									<MButton v-if="!isModEditArticle" id="order-article-send-to-advertiser" :label="currentStatus !== PUBLICATION_REPORT_COMMENT_STATUS ? $t('order.orderFlow.sendToAdvertiser') : $t('order.orderFlow.sendToPublisher')" blue400 normal @click="onSubmit(true)" :loading="loading" :loading-color="white" />
								</div>
							</div>
						</div>
						
						<div v-if="!checkArticleFormAccess()">
							<div class="order__article-menu-wrapper">
								<MMenu location="bottom" min-width="200">
									<template v-slot:menuActivator>
										<MButton :label="$t('order.orderFlow.downloadArticle')" blue400 normal icon-after-text="solid-arrow-down" />
									</template>
									<template v-slot:menuItems>
										<div id="order-download-docs" class="order__article-menu-item" @click="downloadArticleDocs">{{ $t('order.orderFlow.doc') }}</div>
										<div id="order-download-html" class="order__article-menu-item" @click="downloadArticleHtml(true)">{{ $t('order.orderFlow.html') }}</div>
									</template>
								</MMenu>
								<MMenu location="bottom" min-width="200">
									<template v-slot:menuActivator>
										<MButton :label="$t('order.orderFlow.copyArticle')" blue400 normal icon-after-text="solid-arrow-down" />
									</template>
									<template v-slot:menuItems>
										<div id="order-copy-html" class="order__article-menu-item" @click="downloadArticleHtml(false)">{{ $t('order.orderFlow.html') }}</div>
									</template>
								</MMenu>
							</div>
							<div class="order__article-title">{{ articleDetails.title }}</div>
							<div v-html="articleDetails.content" />
							<img v-if="articleDetails.image.length !== 0" :src="articleDetails.image[0].url" alt="marketin9" class="order__image-preview" />
							<OrderChangeStatus v-if="isArticleChangeStatusAvailable" id="article-details-panel-change-status" :label="$t('order.orderFlow.sendArticle')" @changeStatusAction="changeStatusAction" :loading="loading" :loading-color="white" />
						</div>
					</template>
				</MExpansionPanel>
				
				<MExpansionPanel v-if="!isPublicationDetailsHidden" id="publication-details-panel" v-model="expandPublicationDetailsPanel" :title-bg-color="!isPublicationPanelDetailsDisabled ? white : grey300" :content-bg-color="white" :disabled="isPublicationPanelDetailsDisabled" :loading="loading">
					<template v-slot:title>
						<div class="order__subtitle">{{ $t('order.orderFlow.publicationDetails') }}</div>
					</template>
					<template v-slot:text>
						<div class="order__article-details-wrapper">
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.offer') }}</div>
								<div class="order__bold">{{ publicationDetails?.offer }}</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.domainAddress') }}</div>
								<div class="order__bold">{{ publicationDetails?.domain_url }}</div>
							</div>
							<div v-if="null != publicationDetails.section" class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.section') }}</div>
								<div class="order__bold">{{ $t(`dictionary.section.${publicationDetails.section}`) }}</div>
							</div>
							<div v-if="null != publicationDetails.period" class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.period') }}</div>
								<div class="order__bold">{{ $t(`dictionary.period.${publicationDetails?.period}`) }}</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.disclosure') }}</div>
								<div class="order__bold">{{ null != publicationDetails?.disclosure_other ? publicationDetails?.disclosure_other : $t(`dictionary.disclosure.${publicationDetails?.disclosure}`) }}</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.price') }}</div>
								<div class="order__bold">{{ (publicationDetails?.price / 100).toFixed(2) }} {{ null != user && isPublisher(user) ? userCurrencySymbol : changeCurrencyIdToSymbol(publicationDetails?.currency_id) }}</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.doFollow') }}</div>
								<div class="order__bold">{{ 1 === publicationDetails?.doFollow ? $t('components.yes') : $t('components.no') }}</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.maxLinks') }}</div>
								<div class="order__bold">{{ publicationDetails?.maxLinks }}</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.linkType') }}</div>
								<div class="order__bold">
									<div v-for="anchor in publicationDetails.anchors" :key="anchor.name" class="order__anchor_wrapper">{{ $t(`order.orderFlow.${anchor.name}`) }}</div>
								</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.preferPublicationDate') }}</div>
								<div class="order__bold">{{ '0000-00-00' === publicationDetails?.preferPublishDate ? $t('components.asap') : dateFormatWithTime(publicationDetails?.preferPublishDate) }}</div>
							</div>
							<div class="order__horizontal">
								<div class="order__size-14">{{ $t('order.orderFlow.publicationDate') }}</div>
								<div class="order__bold">{{ currentStatus === FINISHED_STATUS ? dateFormatWithTime(publicationDetails?.publishDate) : '-' }}</div>
							</div>
							<div v-if="null != publicationDetails.publisher_notes" class="order__size-14">{{ $t('order.orderFlow.noteToPublisher') }}</div>
							<div v-if="null != publicationDetails.publisher_notes" class="order__bold">{{ publicationDetails?.publisher_notes }}</div>
						</div>
					</template>
				</MExpansionPanel>
				
				<MExpansionPanel v-if="!isPublicationHidden" id="publication-panel" v-model="expandPublicationPanel" :title-bg-color="!isPublicationPanelDisabled ? white : grey300" :content-bg-color="white" :disabled="isPublicationPanelDisabled" :loading="loading">
					<template v-slot:title>
						<div class="order__subtitle">{{ $t('order.orderFlow.publication') }}</div>
					</template>
					<template v-slot:text>
						<div v-if="checkPublicationFormAccess()" class="order__publication">
							<Form :validation-schema="publicationSchema" @submit="onPublicationSubmit">
								<MTextField
									id="order-publication-link"
									name="publicationLink"
									v-model="publicationForm.link"
									label-text-required
									:separate-label="$t('order.orderFlow.publicationLink')"
									:placeholder="$t('order.orderFlow.publicationLinkPlaceholder')"
									:hint="$t('websites.addHttps')"
									clearable
								/>
								<MTextField
									id="order-publication-link-facebook"
									name="publicationLinkFacebook"
									v-model="publicationForm.facebook"
									:separate-label="$t('order.orderFlow.publicationLinkFacebook')"
									:placeholder="$t('order.orderFlow.publicationLinkPlaceholder')"
									:hint="$t('websites.addHttps')"
									clearable
								/>
								<MButton
									id="order-publication-links-submit"
									:label="$t('order.orderFlow.save')"
									normal
									blue400
									:loading="loading"
									:loading-color="white"
								/>
							</Form>
						</div>
						
						<div v-if="!checkPublicationFormAccess()">
							<div class="order__article-details-wrapper">
								<div class="order__horizontal">
									<div class="order__size-14">{{ $t('order.orderFlow.publicationLink') }}</div>
									<a class="order__bold order__link" :href="publicationDetails.url" target="_blank">{{ publicationDetails.url }}</a>
								</div>
								<div class="order__horizontal">
									<div class="order__size-14">{{ $t('order.orderFlow.publicationLinkFacebook') }}</div>
									<a class="order__bold order__link" :href="publicationDetails.fb_link" target="_blank">{{ publicationDetails?.fb_link }}</a>
								</div>
							</div>
							<OrderChangeStatus v-if="isPublicationChangeStatusAvailable" :label="$t('order.orderFlow.publicationLink')" @changeStatusAction="changeStatusAction" :loading="loading" :loading-color="white" />
						</div>
					</template>
				</MExpansionPanel>
				
				<MExpansionPanel v-if="checkContentWriterPanelAvailable()" id="content-writer-panel" v-model="expandContentWriterPanel" :title-bg-color="white" :content-bg-color="white" :loading="loading">
					<template v-slot:title>
						<div class="order__subtitle">{{ $t('order.orderFlow.changeContentWriter') }}</div>
					</template>
					<template v-slot:text>
						<Form :validation-schema="contentWriterSchema" @submit="onContentWriterSubmit">
							<MAutocomplete
								id="change-content-writer"
								v-model="contentWriterId"
								name="changeContentWriter"
								:placeholder="$t('articles.selectAccount')"
								label-text-required
								:label="$t('order.orderFlow.changeContentWriter')"
								:get-items="contentWriterList"
								variant="outlined"
								item-title="email"
								item-value="id"
								clearable
							/>
							<MButton
								id="change-content-writer-submit-btn"
								:label="$t('order.orderFlow.save')"
								normal
								blue400
								:loading="loading"
								:loading-color="white"
							/>
						</Form>
					</template>
				</MExpansionPanel>
			</div>
			
			<div class="order__right-wrapper">
				<OrderActionBox v-if="null != user" :status="currentStatus" :user-role-id="user.active_role_id" :order-type="currentOrderType" @scrollToAction="quickAcceptAction" />
				<OrderChat :messages="messages" @sendMessage="sendMessage" :current-status="currentStatus" :order-type="currentOrderType" :content-writer-target-id="contentWriterTargetId" :advertiser-target-id="advertiserTargetId" :publisher-target-id="publisherTargetId" />
				<div class="order__cancel-btn-wrapper">
					<MButton
						v-if="checkCancelOrderBtnAccess()"
						id="order-open-dialog-cancel-order"
						:label="$t('order.orderFlow.cancelOrder')"
						normal
						@click="openDialog"
					/>
				</div>
			</div>
		</div>
		
		<MDialog
			:is-open-dialog-action="isOpenCancelDialog"
			:is-close-dialog-action="isCloseCancelDialog"
			is-select-footer-btn
			:content-title="`${$t('order.orderFlow.cancelOrderTitle')}`"
			:close-btn="false"
			close-header-btn
			is-large-font-size-title
			@closeDialog="closeAction()"
		>
			<template v-slot:default>
				<div class="order__reject-wrapper">
					<div>{{ $t('order.orderFlow.cancelOrderInfo') }}</div>
					<Form :validation-schema="cancelSchema" @submit="cancelOrder" class="order__width-100-percent">
						<MTextArea
							id="order-article-cancel-comment"
							name="cancelComments"
							v-model="cancelComments"
							:separate-label="$t('order.orderFlow.resignationReason')"
							:placeholder="$t('order.orderFlow.resignationReason')"
						/>
						<div class="order__btn-wrapper">
							<MButton id="order-cancel-order" type="submit" :label="$t('order.orderFlow.cancelOrder')" normal transparent-with-border :loading="loading" />
							<MButton normal blue400 :label="$t('components.close')" @click="closeAction" />
						</div>
					</Form>
				</div>
			</template>
		</MDialog>
		
		<MDialog
			:is-open-dialog-action="isOpenReportCommentsDialog"
			:is-close-dialog-action="isCloseReportCommentsDialog"
			is-select-footer-btn
			:content-title="`${$t('order.orderFlow.sendReports')}`"
			:close-btn="false"
			close-header-btn
			is-large-font-size-title
			@closeDialog="closeAction()"
		>
			<template v-slot:default>
				<div class="order__reject-wrapper">
					<Form :validation-schema="reportSchema" @submit="sendReports" class="order__width-100-percent">
						<MTextArea
							id="order-article-report-comments"
							name="reportComments"
							v-model="reportComments"
							:separate-label="$t('order.orderFlow.reports')"
							:placeholder="$t('order.orderFlow.reports')"
						/>
						<div class="order__btn-wrapper">
							<MButton id="order-send-reports" type="submit" :label="$t('order.orderFlow.sendReports')" normal transparent-with-border :loading="loading" />
							<MButton normal blue400 :label="$t('components.close')" @click="closeAction"/>
						</div>
					</Form>
				</div>
			</template>
		</MDialog>
	</div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import router from '@/router';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useDictionaryStore } from '@/stores/dictionary';
import { Form } from 'vee-validate';
import * as yup from 'yup';
import { toast } from 'vue3-toastify';
import variables from '@/assets/scss/modules/variables.module.scss';
import {
	ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE,
	ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE,
	ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE,
	ORDER_TYPE_ARTICLE,
	RESIGNED_STATUS,
	CONTENT_IN_PROGRESS_STATUS,
	CONTENT_SUBMITTED_STATUS,
	CONTENT_DISAPPROVED_STATUS,
	PUBLICATION_ASSIGNED_STATUS,
	PUBLICATION_IN_PROGRESS_STATUS,
	PUBLICATION_REPORT_COMMENT_STATUS,
	PUBLICATION_SUBMITTED_STATUS,
	PUBLICATION_DISAPPROVED_STATUS,
	FINISHED_STATUS,
	MESSAGE_TYPE_ORDER,
	MESSAGE_TYPE_ORDER_COMMENT
} from '@/hooks/OrderFlowHooks';
import {
	isAdvertiser,
	isPublisher,
	isModerator,
	isContentWriter,
	ADVERTISER_ID,
	MODERATOR_ID,
	PUBLISHER_ID,
	CONTENT_WRITER_ID
} from '@/hooks/UserHooks';
import { dateFormatWithTime } from '@/hooks/DataHooks';
import type { IArticleTypes } from '@/models/ArticlesModel';
import type { UserInterface } from '@/models/AuthModel';
import OrderApi from '@/api/v2/OrderApi';
import ArticleApi from '@/api/v1/ArticleApi';
import ImageApi from '@/api/v1/ImageApi';
import ChatApi from '@/api/v1/ChatApi';
import PublicationApi from '@/api/v1/PublicationApi';
import UserApi from '@/api/v1/UserApi';
import MExpansionPanel from '@/components/atoms/MExpansionPanel.vue';
import MTextField from '@/components/atoms/MTextField.vue';
import MVueEditor from '@/components/atoms/MVueEditor.vue';
import MButton from '@/components/atoms/MButton.vue';
import MIcon from '@/components/atoms/MIcon.vue';
import MMenu from '@/components/atoms/MMenu.vue';
import MTextArea from '@/components/atoms/MTextArea.vue';
import OrderChangeStatus from '@/views/Orders/components/OrderChangeStatus.vue';
import OrderActionBox from '@/views/Orders/components/OrderActionBox.vue';
import OrderChat from '@/views/Orders/components/OrderChat.vue';
import MDialog from '@/components/atoms/MDialog.vue';
import MAutocomplete from '@/components/atoms/MAutocomplete.vue';
import type { IMessagesFile } from '@/models/ChatModel';

const orderApi = new OrderApi();
const articleApi = new ArticleApi();
const imageApi = new ImageApi();
const publicationApi = new PublicationApi();
const chatApi = new ChatApi();
const userApi = new UserApi();

const { white, grey300 } = variables;
const { t } = useI18n();
const { user, userCurrencySymbol } = storeToRefs(useUserStore());
const { languages, currencies } = useDictionaryStore();

const currentStatus = ref<string>('');
const currentOrderType = ref<string>('');
const orderGroupUuid = ref<string|Array<string>|null>(null);
const orderGroupId = ref<number|null>(null);
const quickAcceptBtnTitle = ref<string>('');
const expandArticleDetailsPanel = ref<Array<number>>([]);
const isArticlePanelDisabled = ref<boolean>(false);
const expandArticlePanel = ref<Array<number>>([]);
const isArticlePanelDetailsDisabled = ref<boolean>(false);
const expandPublicationDetailsPanel = ref<Array<number>>([]);
const isPublicationPanelDetailsDisabled = ref<boolean>(false);
const expandPublicationPanel = ref<Array<number>>([]);
const isPublicationPanelDisabled = ref<boolean>(false);
const expandContentWriterPanel = ref<Array<number>>([]);
const isPublicationDetailsHidden = ref<boolean>(false);
const isPublicationHidden = ref<boolean>(false);
const isFileError = ref<boolean>(false);
const isArticleDetailsExpandPanelDefaultOpen = ref<boolean>(false);
const isArticleExpandPanelDefaultOpen = ref<boolean>(false);
const isPublicationDetailsExpandPanelDefaultOpen = ref<boolean>(false);
const isPublicationExpandPanelDefaultOpen = ref<boolean>(false);
const isArticleChangeStatusAvailable = ref<boolean>(false);
const isPublicationChangeStatusAvailable = ref<boolean>(false);
const isQuickAction = ref<boolean>(false);
const isArticleSendToAdvertiserAction = ref<boolean>(false);
const loading = ref<boolean>(true);
const typesOfArticle = ref<Array<IArticleTypes>>([]);
const publicationUuid = ref<string|null>(null);
const isOpenCancelDialog = ref<boolean>(false);
const isOpenReportCommentsDialog = ref<boolean>(false);
const isCloseCancelDialog = ref<boolean>(true);
const isCloseReportCommentsDialog = ref<boolean>(true);
const reportComments = ref<string|null>(null);
const cancelComments = ref<string|null>(null);
const articleRequestUuid = ref<string|null>(null);
const contentWriterId = ref<number|null>(null);
const contentWriterList = ref<Array<UserInterface>>([]);
const isModEditArticle = ref<boolean>(false);
const articleSize = ref<number>(0);
const advertiserTargetId = ref<number | null>(null);
const publisherTargetId = ref<number | null>(null);
const contentWriterTargetId = ref<number | null>(null);
const articleDetails = ref<any>({
	uuid: null,
	id: null,
	campaign: null,
	campaign_id: null,
	language_id: null,
	title: null,
	type: null,
	links: null,
	reports: null,
	comments: null,
	content: null,
	image: null,
});
const publicationDetails = ref<any>({
	offer: null,
	domain_url: null,
	section: null,
	period: null,
	disclosure: null,
	disclosure_other: null,
	price: null,
	currency_id: null,
	doFollow: null,
	maxLinks: null,
	anchors: null,
	preferPublishDate: null,
	publishDate: null,
	publisher_notes: null,
	fb_link: null,
	url: null,
});
const messages = ref<Array<any>>([]);
const articleForm = ref<any>({
	title: null,
	content: null,
	file: null,
	publisherNote: null,
});
const publicationForm = ref<any>({
	link: null,
	facebook: null,
});

const gtm = {
	pushEvent(event:string, name:string, value:any) {
		if (null != window.dataLayer) {
			window.dataLayer.push({
				event: event,
				[name]: value
			});
		}
	}
};

orderGroupUuid.value = router.currentRoute.value.params.orderUuid;

const schema:any = yup.object({
	articleTitle: yup.string().required(`${t('order.orderFlow.validation.articleRequired')}`).label('articleTitle'),
	articleContent: yup.string().required(`${t('order.orderFlow.validation.articleContentRequired')}`).label('articleContent').test('articleContent', `${t('order.orderFlow.validation.mbSize')}`,
	function (val) {
		const contentBytesSize = new Blob([val]).size;
		if (contentBytesSize > 19664471) {
			return false;
		} else {
			return true;
		}
	}),
});

const reportSchema:any = yup.object({
	reportComments: yup.string().required(`${t('order.orderFlow.validation.rejectNoteRequired')}`).label('reportComments'),
});

const cancelSchema:any = yup.object({
	cancelComments: yup.string().required(`${t('order.orderFlow.validation.cancelCommentsRequired')}`).label('cancelComments'),
});

const publicationSchema:any = yup.object({
	publicationLink: yup.string().required(`${t('order.orderFlow.validation.publicationLinkRequired')}`).label('publicationLink').test('publicationLink', `${t('websites.validation.https')}`,
	function (val) {
		if (null != val) {
			const re = /^(http|https):\/\/.*/gm;
			if (val.match(re)) {
				return true;
			}
			return false;
		}
		return false;
	}),
	publicationLinkFacebook: yup.string().nullable().label('publicationLinkFacebook').test('publicationLinkFacebook', `${t('websites.validation.https')}`,
	function (val) {
		if (null != val) {
			const re = /^(http|https):\/\/.*/gm;
			if (val.match(re)) {
				return true;
			}
			return false;
		}
		return true;
	}),
});

const contentWriterSchema:any = yup.object({
	changeContentWriter: yup.string().required(`${t('order.orderFlow.validation.changeContentWriter')}`).label('changeContentWriter'),
});

const onChangeImage = (event:any) => {
	articleForm.value.file = null;
	Array.from(event.target.files).forEach(file => {
		articleForm.value.file = file;
	});
};
const dragover = (event:any) => {
	event.preventDefault();
	// Add some visual fluff to show the user can drop its files
	if (!event.currentTarget.classList.contains('order__bg-green-300')) {
		event.currentTarget.classList.remove('order__bg-gray-100');
		event.currentTarget.classList.add('order__bg-green-300');
	}
};
const dragleave = (event:any) => {
	// Clean up
	event.currentTarget.classList.add('order__bg-gray-100');
	event.currentTarget.classList.remove('order__bg-green-300');
};
const drop = (event:any) => {
	articleForm.value.file = null;
	event.preventDefault();
	event.currentTarget.classList.add('order__bg-gray-100');
	event.currentTarget.classList.remove('order__bg-green-300');
	
	Array.from(event.dataTransfer.files).forEach(file => {
		articleForm.value.file = file;
	});
};
const removeFile = () => {
	articleForm.value.file = null;
};
const onSubmitFileCheck = (sendToAdvertiser:boolean) => {
	if (sendToAdvertiser) {
		isArticleSendToAdvertiserAction.value = true;
	}
	if (null == articleForm.value.file) {
		isFileError.value = true;
		return;
	}
};

const goToArticlePreview = (articleUuid:string) => {
	router.push(`/article/${articleUuid}/preview`);
};

watch(([articleForm.value, publicationForm.value]), (val) => {
	if (null != val[0].file) {
		isFileError.value = false;
	} else {
		isFileError.value = true;
	}
	
	if (val[1].facebook === '') {
		publicationForm.value.facebook = null;
	}
}, {
	deep: true,
});

const quickAcceptAction = () => {
	let sectionTop = null;
	if (isArticleExpandPanelDefaultOpen.value) {
		if (null == expandArticlePanel.value) {
			expandArticlePanel.value = [0];
		}
		const section = document.getElementById('article-details-panel-change-status');
		if (null != section) {
			sectionTop = section.offsetTop;
		} else {
			const section = document.getElementById('article-panel');
			sectionTop = null != section ? section.offsetTop : null;
		}
	}
	if (isArticleDetailsExpandPanelDefaultOpen.value) {
		if (null == expandArticleDetailsPanel.value) {
			expandArticleDetailsPanel.value = [0];
		}
		const section = document.getElementById('article-details-panel');
		sectionTop = null != section ? section.offsetTop : null;
	}
	if (isPublicationExpandPanelDefaultOpen.value) {
		if (null == expandPublicationPanel.value) {
			expandPublicationPanel.value = [0];
		}
		const section = document.getElementById('publication-panel');
		sectionTop = null != section ? section.offsetTop : null;
	}
	if (isPublicationDetailsExpandPanelDefaultOpen.value) {
		if (null == expandPublicationDetailsPanel.value) {
			expandPublicationDetailsPanel.value = [0];
		}
		const section = document.getElementById('publication-details-panel');
		sectionTop = null != section ? section.offsetTop : null;
	}
	window.scrollTo({
		top: null != sectionTop ? sectionTop : undefined,
		behavior: 'smooth'
	});
};

const checkExpansionPanelsAccess = () => {
	isArticleChangeStatusAvailable.value = false;
	isQuickAction.value = false;
	isPublicationChangeStatusAvailable.value = false;
	isArticlePanelDisabled.value = false;
	isArticlePanelDetailsDisabled.value = false;
	isPublicationPanelDetailsDisabled.value = false;
	isPublicationPanelDisabled.value = false;
	isArticleExpandPanelDefaultOpen.value = false;
	isArticleDetailsExpandPanelDefaultOpen.value = false;
	isPublicationExpandPanelDefaultOpen.value = false;
	isPublicationDetailsExpandPanelDefaultOpen.value = false;
	expandArticlePanel.value = [];
	expandArticleDetailsPanel.value = [];
	expandPublicationPanel.value = [];
	expandPublicationDetailsPanel.value = [];

	if (null != user.value) {
		if (isAdvertiser(user.value)) {
			if (currentOrderType.value === ORDER_TYPE_ARTICLE) {
				isPublicationDetailsHidden.value = true;
				isPublicationHidden.value = true;
				if (currentStatus.value === CONTENT_IN_PROGRESS_STATUS) {
					isArticlePanelDisabled.value = true;
				}
				if (currentStatus.value === CONTENT_SUBMITTED_STATUS) {
					isArticleChangeStatusAvailable.value = true;
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptArticle')}`;
					expandArticlePanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
			}
			if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE) {
				if (currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === PUBLICATION_REPORT_COMMENT_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixArticle')}`;
					isPublicationPanelDisabled.value = true;
					expandArticlePanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
					isQuickAction.value = true;
					isPublicationChangeStatusAvailable.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptPublication')}`;
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
			}
			if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE || currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) {
				if (currentStatus.value === CONTENT_IN_PROGRESS_STATUS) {
					isArticlePanelDisabled.value = true;
					isPublicationPanelDetailsDisabled.value = true;
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === CONTENT_SUBMITTED_STATUS) {
					isArticleChangeStatusAvailable.value = true;
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptArticle')}`;
					isPublicationPanelDetailsDisabled.value = true;
					isPublicationPanelDisabled.value = true;
					expandArticlePanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === CONTENT_DISAPPROVED_STATUS) {
					isPublicationPanelDetailsDisabled.value = true;
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === PUBLICATION_IN_PROGRESS_STATUS) {
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === PUBLICATION_REPORT_COMMENT_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixArticle')}`;
					isPublicationPanelDisabled.value = true;
					expandArticlePanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
					isQuickAction.value = true;
					isPublicationChangeStatusAvailable.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptPublication')}`;
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
			}
		}
		
		if (isPublisher(user.value)) {
			if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE) {
				if (currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
					isQuickAction.value = true;
					isArticleChangeStatusAvailable.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptArticle')}`;
					isPublicationPanelDisabled.value = true;
					expandArticleDetailsPanel.value.push(0);
					expandArticlePanel.value.push(0);
					expandPublicationDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_REPORT_COMMENT_STATUS) {
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === PUBLICATION_IN_PROGRESS_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.addLinks')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_DISAPPROVED_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixPublication')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
			}
			if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE) {
				if (currentStatus.value === CONTENT_IN_PROGRESS_STATUS) {
					isArticlePanelDisabled.value = true;
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === CONTENT_SUBMITTED_STATUS) {
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === CONTENT_DISAPPROVED_STATUS) {
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
					isQuickAction.value = true;
					isArticleChangeStatusAvailable.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptArticle')}`;
					isPublicationPanelDisabled.value = true;
					expandArticleDetailsPanel.value.push(0);
					expandArticlePanel.value.push(0);
					expandPublicationDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_REPORT_COMMENT_STATUS) {
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === PUBLICATION_IN_PROGRESS_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.addLinks')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_DISAPPROVED_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixPublication')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
			}
			
			if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) {
				if (currentStatus.value === CONTENT_IN_PROGRESS_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.writeArticle')}`;
					expandArticlePanel.value.push(0);
					expandArticleDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === CONTENT_SUBMITTED_STATUS) {
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === CONTENT_DISAPPROVED_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixArticle')}`;
					expandArticlePanel.value.push(0);
					expandArticleDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
					isPublicationPanelDisabled.value = true;
				}
				if (currentStatus.value === PUBLICATION_IN_PROGRESS_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.addLinks')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_DISAPPROVED_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixPublication')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
			}
		}
		
		if (isContentWriter(user.value)) {
			if (currentOrderType.value === ORDER_TYPE_ARTICLE || currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE) {
				isPublicationDetailsHidden.value = true;
				isPublicationHidden.value = true;
				if (currentStatus.value === CONTENT_IN_PROGRESS_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.writeArticle')}`;
					expandArticlePanel.value.push(0);
					expandArticleDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === CONTENT_DISAPPROVED_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixArticle')}`;
					expandArticlePanel.value.push(0);
					expandArticleDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
			}
		}
		
		if (isModerator(user.value)) {
			if (currentOrderType.value === ORDER_TYPE_ARTICLE) {
				isPublicationDetailsHidden.value = true;
				isPublicationHidden.value = true;
				if (currentStatus.value === CONTENT_IN_PROGRESS_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.writeArticle')}`;
					expandArticlePanel.value.push(0);
					expandArticleDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === CONTENT_SUBMITTED_STATUS) {
					isQuickAction.value = true;
					isArticleChangeStatusAvailable.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptArticle')}`;
					expandArticlePanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === CONTENT_DISAPPROVED_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixArticle')}`;
					expandArticlePanel.value.push(0);
					expandArticleDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
			}
			if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE) {
				if (currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
					isArticleChangeStatusAvailable.value = true;
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptArticle')}`;
					isPublicationPanelDisabled.value = true;
					expandArticleDetailsPanel.value.push(0);
					expandArticlePanel.value.push(0);
					expandPublicationDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_REPORT_COMMENT_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixArticle')}`;
					isPublicationPanelDisabled.value = true;
					expandArticlePanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_IN_PROGRESS_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.addLinks')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
					isQuickAction.value = true;
					isPublicationChangeStatusAvailable.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptPublication')}`;
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_DISAPPROVED_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixPublication')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
			}
			if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE || currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) {
				if (currentStatus.value === CONTENT_IN_PROGRESS_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.writeArticle')}`;
					isPublicationPanelDetailsDisabled.value = true;
					isPublicationPanelDisabled.value = true;
					expandArticlePanel.value.push(0);
					expandArticleDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === CONTENT_SUBMITTED_STATUS) {
					isQuickAction.value = true;
					isArticleChangeStatusAvailable.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptArticle')}`;
					isPublicationPanelDetailsDisabled.value = true;
					isPublicationPanelDisabled.value = true;
					expandArticlePanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === CONTENT_DISAPPROVED_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixArticle')}`;
					isPublicationPanelDetailsDisabled.value = true;
					isPublicationPanelDisabled.value = true;
					expandArticlePanel.value.push(0);
					expandArticleDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
					isArticleChangeStatusAvailable.value = true;
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptArticle')}`;
					isPublicationPanelDisabled.value = true;
					expandArticleDetailsPanel.value.push(0);
					expandArticlePanel.value.push(0);
					expandPublicationDetailsPanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_REPORT_COMMENT_STATUS) {
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixArticle')}`;
					isPublicationPanelDisabled.value = true;
					expandArticlePanel.value.push(0);
					isArticleExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_IN_PROGRESS_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.addLinks')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
					isPublicationChangeStatusAvailable.value = true;
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.acceptPublication')}`;
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
				if (currentStatus.value === PUBLICATION_DISAPPROVED_STATUS) {
					isQuickAction.value = true;
					quickAcceptBtnTitle.value = `${t('order.orderFlow.fixPublication')}`;
					expandArticlePanel.value.push(0);
					expandPublicationPanel.value.push(0);
					isPublicationExpandPanelDefaultOpen.value = true;
				}
			}
		}
	}
};
const orderDetails = async() => {
	loading.value = true;
	if (null == orderGroupUuid.value) {
		toast.error(`${t('order.orderFlow.toast.errorDetails')}`);
		loading.value = false;
		return;
	}
	try {
		const result = await orderApi.itemGroupDetails(orderGroupUuid.value);
		if (!result.isSuccess) {
			toast.error(`${t('order.orderFlow.toast.errorDetails')}`);
			loading.value = false;
			return;
		}
		advertiserTargetId.value = result.payload.article?.campaign_owner_id;
		publisherTargetId.value = result.payload.publication_request?.offer.website_owner.id;
		contentWriterTargetId.value = result.payload.article_request?.content_writer_id;
		currentStatus.value = result.payload.status;
		if (null == result.payload.publication_request) {
			currentOrderType.value = ORDER_TYPE_ARTICLE;
		}
		if (null == result.payload.article_request) {
			currentOrderType.value = ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE;
		}
		if (null != result.payload.publication_request && null != result.payload.article_request && false === result.payload.publication_request.is_article_write_by_publisher) {
			currentOrderType.value = ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE;
		}
		if (null != result.payload.publication_request && null != result.payload.article_request && true === result.payload.publication_request.is_article_write_by_publisher) {
			currentOrderType.value = ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE;
		}
		
		orderGroupId.value = result.payload.id;
		
		articleDetails.value.uuid = result.payload.article?.uuid;
		articleDetails.value.id = result.payload.article?.id;
		articleDetails.value.image = result.payload.article?.images;
		articleDetails.value.content = result.payload.article?.content;
		if (null != result.payload.article?.campaign) {
			articleDetails.value.campaign = result.payload.article?.campaign?.name;
		} else {
			articleDetails.value.campaign = `${t('components.noCampaign')}`;
		}
		
		if (null != result.payload.article?.campaign) {
			articleDetails.value.campaign_id = result.payload.article?.campaign.id;
		}

		articleDetails.value.language_id = result.payload.article?.language_id;
		articleDetails.value.title = result.payload.article?.title;
		articleDetails.value.reports = result.payload.article?.publisher_note;
		articleDetails.value.comments = result.payload.article_request?.comment;
		if (null != result.payload.article) {
			await articleTypes(result.payload.article.language_id);
		}
		const articleType = typesOfArticle.value.find(elem => elem.id === result.payload.article_request?.article_type_id);
		if (null != articleType) {
			articleDetails.value.type = `${t(`order.orderFlow.${articleType.type}`)}`;
		}
		if (null != result.payload.article_request) {
			articleDetails.value.links = result.payload.article_request.links;
		}
		
		articleForm.value.title = result.payload.article?.title;
		articleForm.value.content = result.payload.article?.content;
		articleForm.value.publisherNote = result.payload.article?.publisher_note;
		
		if (currentOrderType.value !== ORDER_TYPE_ARTICLE) {
			publicationDetails.value.offer = result.payload.publication_request?.offer?.name;
			publicationDetails.value.domain_url = result.payload.publication_request?.offer?.url;
			publicationDetails.value.section = result.payload.publication_request?.offer?.section.id;
			publicationDetails.value.period = result.payload.publication_request?.offer?.duration.id;
			publicationDetails.value.disclosure = result.payload.publication_request?.offer?.disclosure;
			publicationDetails.value.disclosure_other = result.payload.publication_request?.offer?.disclosure_other;
			publicationDetails.value.price = null != user.value && isAdvertiser(user.value) ? result.payload.publication_item?.price : result.payload.publication_request?.offer?.price;
			publicationDetails.value.currency_id = result.payload.publication_request?.offer?.currency_id;
			publicationDetails.value.doFollow = result.payload.publication_request?.offer?.do_follow;
			publicationDetails.value.maxLinks = result.payload.publication_request?.offer?.links_max_amount;
			publicationDetails.value.anchors = result.payload.publication_request?.offer?.anchor_types;
			publicationDetails.value.preferPublishDate = result.payload.publication_request?.publication_date;
			publicationDetails.value.publishDate = result.payload.updated_at;
			publicationDetails.value.url = result.payload.publication?.url;
			publicationDetails.value.fb_link = result.payload.publication?.fb_link;
			
			publicationForm.value.facebook = result.payload.publication?.fb_link;
			publicationForm.value.link = result.payload.publication?.url;
			
			if (null != result.payload.publication) {
				publicationUuid.value = result.payload.publication.uuid;
			}
		}
		
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) {
			publicationDetails.value.publisher_notes = result.payload.article?.publisher_note;
		}
		
		await chatList();
		
		if (checkContentWriterPanelAvailable() && null != result.payload.article_request) {
			contentWriterId.value = result.payload.article_request?.content_writer_id;
			articleRequestUuid.value = result.payload.article_request?.uuid;
			await getContentWriterList();
		}
	} catch (e) {
		toast.error(`${t('order.orderFlow.toast.errorDetails')}`);
		loading.value = false;
		return;
	}
	await checkExpansionPanelsAccess();
	loading.value = false;
};
orderDetails();

const onSubmit = async(isArticleSendToAdvertiser: boolean) => {
	loading.value = true;
	if (null == articleForm.value.file && 0 === articleDetails.value.image.length) {
		isFileError.value = true;
		loading.value = false;
		return;
	}
	onSubmitFileCheck(isArticleSendToAdvertiser);
	
	const articleEditForm = {
		campaign_id: articleDetails.value.campaign_id,
		language_id: articleDetails.value.language_id,
		title: articleForm.value.title,
		content: articleForm.value.content,
		publisher_note: articleForm.value.publisherNote,
		footer_id: null,
	};
	try {
		const result = await articleApi.editArticle(articleDetails.value.uuid, articleEditForm);
		if (!result.isSuccess) {
			toast.error(`${t('order.orderFlow.toast.errorEditArticle')}`);
			loading.value = false;
			return;
		}
		if (null != articleForm.value.file) {
			await uploadImage();
		}
		toast.success(`${t('order.orderFlow.toast.successEditArticle')}`);
		if (isArticleSendToAdvertiserAction.value) {
			await changeStatusAction(true, null);
		} else {
			await orderDetails();
		}
	} catch (e) {
		toast.error(`${t('order.orderFlow.toast.errorEditArticle')}`);
		loading.value = false;
		return;
	}
	
	isArticleSendToAdvertiserAction.value = false;
	loading.value = false;
};

const uploadImage = async(isEditableImage?:boolean) => {
	loading.value = true;
	try {
		const result = await imageApi.sendImage(articleDetails.value.id, isEditableImage ? articleForm.value.file[0] : articleForm.value.file);
		if(!result.isSuccess) {
			toast.error(`${t('order.orderFlow.toast.errorUploadPhoto')}`);
			loading.value = false;
			return;
		}
		if (isEditableImage) {
			articleDetails.value.image = [];
			articleDetails.value.image.push(result.payload);
		}
	} catch (e) {
		toast.error(`${t('order.orderFlow.toast.errorUploadPhoto')}`);
		loading.value = false;
		return;
	}
	articleForm.value.file = null;
	loading.value = false;
};
const onPublicationSubmit = async() => {
	loading.value = true;
	
	let publicationEditForm:any = {};
	if (null == publicationForm.value.facebook || '' === publicationForm.value.facebook) {
		publicationEditForm.url = publicationForm.value.link;
	} else {
		publicationEditForm.url = publicationForm.value.link;
		publicationEditForm.fb_link = publicationForm.value.facebook;
	}
	try {
		const result = await publicationApi.changePublicationLinks(publicationUuid.value, publicationEditForm);
		if (!result.isSuccess) {
			toast.error(`${t('order.orderFlow.toast.errorChangePublicationLinks')}`);
			loading.value = false;
			return;
		}
		await changeStatusAction(true, null);
	} catch (e) {
		toast.error(`${t('order.orderFlow.toast.errorChangePublicationLinks')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
const cancelOrder = async() => {
	closeAction();
	await sendMessage(cancelComments.value, false, true, null);
	await changeStatusAction(false, null, true);
};

const changeStatusAction = async(decision:boolean, note:string|null, isResignedStatus?: boolean) => {
	loading.value = true;
	
	if (null == orderGroupUuid.value) {
		toast.error(t('order.orderFlow.toast.errorChangeStatus'));
		loading.value = false;
		return;
	}
	
	let orderStatusForm = {
		status: '',
	};
	if (isResignedStatus) {
		toast.success(`${t('order.orderFlow.toast.orderResigned')}`);
		orderStatusForm.status = RESIGNED_STATUS;
	} else if (decision) {
		// Akcje potwierdzające przesyłanie artykułu
		if ((currentOrderType.value === ORDER_TYPE_ARTICLE || currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE || currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) && (currentStatus.value === CONTENT_IN_PROGRESS_STATUS || currentStatus.value === CONTENT_DISAPPROVED_STATUS)) {
			toast.success(`${t('order.orderFlow.toast.articleSendToApproval')}`);
			orderStatusForm.status = CONTENT_SUBMITTED_STATUS;
		}
		if ((currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE || currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE) && currentStatus.value === PUBLICATION_REPORT_COMMENT_STATUS) {
			toast.success(`${t('order.orderFlow.toast.articleSendToApproval')}`);
			orderStatusForm.status = PUBLICATION_ASSIGNED_STATUS;
		}
		// Akcje potwierdzające przesyłanie publikacji
		if ((currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE || currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE || currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE) && (currentStatus.value === PUBLICATION_IN_PROGRESS_STATUS || currentStatus.value === PUBLICATION_DISAPPROVED_STATUS)) {
			toast.success(`${t('order.orderFlow.toast.publicationSendToApproval')}`);
			orderStatusForm.status = PUBLICATION_SUBMITTED_STATUS;
		}
		// Akcje potwierdzające pozytywny wybór radio buttonów
		if (currentOrderType.value === ORDER_TYPE_ARTICLE && currentStatus.value === CONTENT_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.successArticle')}`);
			orderStatusForm.status = FINISHED_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE && currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.successArticle')}`);
			orderStatusForm.status = PUBLICATION_IN_PROGRESS_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE && currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.successPublication')}`);
			orderStatusForm.status = FINISHED_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE && currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.successArticle')}`);
			orderStatusForm.status = PUBLICATION_IN_PROGRESS_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE && currentStatus.value === CONTENT_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.successArticle')}`);
			orderStatusForm.status = PUBLICATION_ASSIGNED_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE && currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.successPublication')}`);
			orderStatusForm.status = FINISHED_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE && currentStatus.value === CONTENT_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.successArticle')}`);
			orderStatusForm.status = PUBLICATION_IN_PROGRESS_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE && currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.successPublication')}`);
			orderStatusForm.status = FINISHED_STATUS;
		}
	} else {
		// Akcje odrzucające negatywny wybór radio buttonów
		if (currentOrderType.value === ORDER_TYPE_ARTICLE && currentStatus.value === CONTENT_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.errorArticle')}`);
			orderStatusForm.status = CONTENT_DISAPPROVED_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE && currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.errorArticle')}`);
			orderStatusForm.status = PUBLICATION_REPORT_COMMENT_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_OWN_ARTICLE && currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.errorPublication')}`);
			orderStatusForm.status = PUBLICATION_DISAPPROVED_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE && currentStatus.value === CONTENT_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.errorArticle')}`);
			orderStatusForm.status = CONTENT_DISAPPROVED_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE && currentStatus.value === PUBLICATION_ASSIGNED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.errorArticle')}`);
			orderStatusForm.status = PUBLICATION_REPORT_COMMENT_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE && currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.errorPublication')}`);
			orderStatusForm.status = PUBLICATION_DISAPPROVED_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE && currentStatus.value === CONTENT_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.errorArticle')}`);
			orderStatusForm.status = CONTENT_DISAPPROVED_STATUS;
		}
		if (currentOrderType.value === ORDER_TYPE_PUBLICATION_WITH_PUBLISHER_ARTICLE && currentStatus.value === PUBLICATION_SUBMITTED_STATUS) {
			toast.success(`${t('order.orderFlow.toast.errorPublication')}`);
			orderStatusForm.status = PUBLICATION_DISAPPROVED_STATUS;
		}
	}
	try {
		const result = await orderApi.changeOrderStatus(orderGroupUuid.value, orderStatusForm);
		if (!result.isSuccess) {
			toast.error(t('order.orderFlow.toast.errorChangeStatus'));
			loading.value = false;
			return;
		}
		if (!decision && null != note) {
			await sendMessage(note, false, true, null);
		}
		
		// Dodanie statystyk do google analytics
		if (orderStatusForm.status === RESIGNED_STATUS) {
			gtm.pushEvent('Order resigned', 'order_resigned', 1);
		}
	} catch (e) {
		toast.error(t('order.orderFlow.toast.errorChangeStatus'));
		loading.value = false;
		return;
	}
	loading.value = false;
	await orderDetails();
};

const checkArticleFormAccess = () => {
	return (null != user.value && ((isContentWriter(user.value) || isModerator(user.value)) && (currentStatus.value === CONTENT_IN_PROGRESS_STATUS || currentStatus.value === CONTENT_DISAPPROVED_STATUS || currentStatus.value === PUBLICATION_REPORT_COMMENT_STATUS)) || (null != user.value && isAdvertiser(user.value) && currentStatus.value === PUBLICATION_REPORT_COMMENT_STATUS) || (null != user.value && isPublisher(user.value) && (currentStatus.value === CONTENT_IN_PROGRESS_STATUS || currentStatus.value === CONTENT_DISAPPROVED_STATUS))) || isModEditArticle.value;
};
const checkPublicationFormAccess = () => {
	return (null != user.value && (isPublisher(user.value) || isModerator(user.value)) && (currentStatus.value === PUBLICATION_IN_PROGRESS_STATUS || currentStatus.value === PUBLICATION_DISAPPROVED_STATUS));
};

const checkCancelOrderBtnAccess = () => {
	 return (
		 (null != user.value && isModerator(user.value) && currentStatus.value !== RESIGNED_STATUS && currentStatus.value !== FINISHED_STATUS) ||
		 (null != user.value && isAdvertiser(user.value) && (currentStatus.value === CONTENT_SUBMITTED_STATUS || currentStatus.value === PUBLICATION_SUBMITTED_STATUS)) ||
		 (null != user.value && isPublisher(user.value) && (currentStatus.value === PUBLICATION_ASSIGNED_STATUS || currentStatus.value === CONTENT_IN_PROGRESS_STATUS))
	 );
};

const sendMessage = async(message:string|null, isPrivate:boolean, isReportType?:boolean, targetUserRoleId?:number|null, files?: IMessagesFile[]) => {
	if ((null == files && null == message) || null == user.value) {
		return;
	}

	const requestFiles:File[] = [];
	if (null != files && files.length > 0) {
		files.forEach((file: IMessagesFile) => {
			requestFiles.push(file.file);
		});
	}
	let targetUserId = null;
	if (targetUserRoleId === MODERATOR_ID) {
		targetUserId = null;
	}
	if (targetUserRoleId === ADVERTISER_ID) {
		targetUserId = advertiserTargetId.value;
	}
	if (targetUserRoleId === PUBLISHER_ID) {
		targetUserId = publisherTargetId.value;
	}
	if (targetUserRoleId === CONTENT_WRITER_ID) {
		targetUserId = contentWriterId.value;
	}
	const request:any = {
		user_id: user.value?.id,
		role_id: user.value?.active_role_id,
		type: isReportType ? MESSAGE_TYPE_ORDER_COMMENT : MESSAGE_TYPE_ORDER,
		source_id: orderGroupId.value,
		content: message,
		// To chwilowo docelowo powinno byc to co ponizej wykomentowane
		// private: isPrivate ? 1 : 0,
		private: isPrivate || !isModerator(user.value) ? 1 : 0,
		read: isPrivate ? 1 : 0,
		target_user_id: targetUserId,
		files: requestFiles,
	};
	try {
		const result = await chatApi.sendMessage(request);
		if (!result.isSuccess) {
			toast.error(t('order.orderFlow.toast.errorSendMessage'));
			return;
		}
		messages.value.push({
			uuid: result.payload.uuid,
			role_id: +result.payload.role_id,
			content: result.payload.content,
			type: result.payload.type,
			private: +result.payload.private,
			user_id: +result.payload.user_id,
			source_id: +result.payload.source_id,
			read: result.payload.read,
			target_user_id: +result.payload.target_user_id,
			files: result.payload.files,
		});
	} catch (e) {
		toast.error(t('order.orderFlow.toast.errorSendMessage'));
		return;
	}
};

const changeLanguageIdToName = (languageId:number|null) => {
	const language = languages.find(elem => elem.id === languageId);
	if (null != language) {
		return language.name;
	}
	return null;
};
const changeCurrencyIdToSymbol = (currencyId:number|null) => {
	const currency = currencies.find(elem => elem.id === currencyId);
	if (null != currency) {
		return currency.currency_symbol;
	}
	return null;
};

const articleTypes = async(languageId:number) => {
	try {
		const result = await articleApi.articleTypes(languageId);
		if (!result.isSuccess) {
			return;
		}
		typesOfArticle.value = result.payload;
	} catch (e) {
		return;
	}
};

const downloadArticleDocs = async() => {
	loading.value = true;
	if (null == articleDetails.value.uuid) {
		toast.error(t('order.orderFlow.toast.errorDownloadArticle'));
		loading.value = false;
		return;
	}
	try {
		const result = await articleApi.downloadArticleDocs(articleDetails.value.uuid);
		if (!result.isSuccess) {
			toast.error(t('order.orderFlow.toast.errorDownloadArticle'));
			loading.value = false;
			return;
		}
		const binaryString = window.atob(result.payload);
		const binaryLen = binaryString.length;
		const bytes = new Uint8Array(binaryLen);
		for (let i = 0; i < binaryLen; i++) {
			const ascii = binaryString.charCodeAt(i);
			bytes[i] = ascii;
		}
		const blob = new Blob([bytes], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
		const link = document.createElement('a');
		link.href = window.URL.createObjectURL(blob);
		link.download = `${articleDetails.value.title}.docx`;
		link.click();
	} catch (e) {
		toast.error(t('order.orderFlow.toast.errorDownloadArticle'));
		loading.value = false;
		return;
	}
	loading.value = false;
};
const downloadArticleHtml = async(isDownloadAction:boolean) => {
	loading.value = true;
	if (null == articleDetails.value.uuid) {
		toast.error(t('order.orderFlow.toast.errorDownloadArticle'));
		loading.value = false;
		return;
	}
	try {
		const result = await articleApi.downloadArticleHtml(articleDetails.value.uuid);
		if (!result.isSuccess) {
			toast.error(t('order.orderFlow.toast.errorDownloadArticle'));
			loading.value = false;
			return;
		}
		if (isDownloadAction) {
			let blob = new Blob([result.payload], { type: 'application/html'});
			let link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `${articleDetails.value.title}.html`;
			link.click();
		} else {
			await navigator.clipboard.writeText(result.payload);
			toast.success(t('order.orderFlow.toast.successCopyArticleToClipboard'));
		}
	} catch (e) {
		toast.error(t('order.orderFlow.toast.errorDownloadArticle'));
		loading.value = false;
		return;
	}
	loading.value = false;
};

const deleteImage = async() => {
	loading.value = true;
	try {
		const result = await imageApi.deleteImage(articleDetails.value.id);
		if (!result.isSuccess) {
			loading.value = false;
			return;
		}
		articleForm.value.file = null;
	} catch (e) {
		toast.error(t('order.orderFlow.toast.errorDeleteImage'));
		loading.value = false;
		return;
	}
	toast.success(t('order.orderFlow.toast.successDeleteImage'));
	await orderDetails();
	loading.value = false;
};

watch((articleForm.value), async(val) => {
	if (val.file && null != val.file && null != articleDetails.value.image && 0 !== articleDetails.value.image.length) {
		await uploadImage(true);
	}
}, {
	deep: true,
});

const closeAction = () => {
	isOpenCancelDialog.value = false;
	isOpenReportCommentsDialog.value = false;
	isCloseCancelDialog.value = true;
	isCloseReportCommentsDialog.value = true;
};
const openDialog = () => {
	isOpenCancelDialog.value = true;
	isCloseCancelDialog.value = false;
};
const openReportDialog = () => {
	isOpenReportCommentsDialog.value = true;
	isCloseReportCommentsDialog.value = false;
};

const sendReports = () => {
	sendMessage(reportComments.value, false, true, null);
	closeAction();
};

const chatList = async() => {
	if (null == orderGroupId.value) {
		toast.error(t('order.orderFlow.toast.errorOrderGroupChats'));
		return;
	}
	try {
		const result = await chatApi.singleChat(MESSAGE_TYPE_ORDER, orderGroupId.value);
		if (!result.isSuccess) {
			toast.error(t('order.orderFlow.toast.errorOrderGroupChats'));
			return;
		}
		messages.value = result.payload;
	} catch (e) {
		toast.error(t('order.orderFlow.toast.errorOrderGroupChats'));
		return;
	}
};

const checkContentWriterPanelAvailable = () => {
	return null != user.value && isModerator(user.value) && currentStatus.value != FINISHED_STATUS && currentStatus.value != RESIGNED_STATUS && (ORDER_TYPE_ARTICLE === currentOrderType.value || (ORDER_TYPE_PUBLICATION_WITH_M9_ARTICLE === currentOrderType.value && (currentStatus.value === CONTENT_IN_PROGRESS_STATUS || currentStatus.value === CONTENT_DISAPPROVED_STATUS || currentStatus.value === CONTENT_SUBMITTED_STATUS)));
};

const getContentWriterList = async() => {
	try {
		const result = await userApi.contentWritersList();
		if (!result.isSuccess) {
			toast.error(t('order.orderFlow.toast.errorContentWritersList'));
			return;
		}
		contentWriterList.value = result.payload;
	} catch (e) {
		toast.error(t('order.orderFlow.toast.errorContentWritersList'));
		return;
	}
};
const onContentWriterSubmit = async() => {
	loading.value = true;
	if (null == articleRequestUuid.value) {
		loading.value = false;
		toast.error(t('order.orderFlow.toast.errorChangeContentWriter'));
		return;
	}
	try {
		const params = {
			content_writer_id: contentWriterId.value,
			status: 'Assigned',
		};
		
		const result = await articleApi.updateArticleRequestItem(articleRequestUuid.value, params);
		if (!result.isSuccess) {
			loading.value = false;
			toast.error(t('order.orderFlow.toast.errorChangeContentWriter'));
			return;
		}
		toast.success(t('order.orderFlow.toast.successChangeContentWriter'));
	} catch (e) {
		loading.value = false;
		toast.error(t('order.orderFlow.toast.errorChangeContentWriter'));
		return;
	}
	loading.value = false;
};

const editArticle = () => {
	isModEditArticle.value = !isModEditArticle.value;
	checkArticleFormAccess();
};

watch((articleForm.value), (val) => {
	articleSize.value = Number((new Blob([val.content]).size / 1000000).toFixed(2));
}, {
	deep: true,
});
</script>

<style scoped lang="scss">
.order {
	&__title-wrapper {
		display: flex;
		flex-direction: column;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
		}
	}
	
	&__title {
		color: $primary-400;
		font-size: 24px;
		font-weight: 400;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: calc(70% - 20px);
		}
		
		@include media-breakpoint-up(xl) {
			width: calc(100% - 330px)
		}
		
		@include media-breakpoint-up(xxl) {
			width: calc(100% - 354px)
		}
	}
	
	&__wrapper {
		margin-top: 64px;
		display: flex;
		flex-direction: column-reverse;
		
		@include media-breakpoint-up(lg) {
			flex-direction: row;
			gap: 20px;
		}
	}
	
	&__left-wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: calc(70% - 20px);
		}
		
		@include media-breakpoint-up(xl) {
			width: calc(100% - 330px)
		}
		
		@include media-breakpoint-up(xxl) {
			width: calc(100% - 354px)
		}
	}
	
	&__right-wrapper {
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: 35%;
		}
		
		@include media-breakpoint-up(xl) {
			width: 400px;
		}
	}
	
	&__article-details-wrapper {
		display: flex;
		flex-direction: column;
		gap: 24px;
	}
	
	&__subtitle {
		font-size: 16px;
	}
	
	&__horizontal {
		display: flex;
		justify-content: space-between;
	}
	
	&__size-14 {
		font-size: 14px;
	}
	
	&__bold {
		font-weight: 700;
	}
	
	&__link {
		color: $primary-400;
	}
	
	&__long-text {
		width: 50%;
		text-align: right;
	}
	
	&__drop-wrapper {
		border: 2px dashed $grey-300;
		border-radius: 12px;
		
		&--error {
			border-color: $secondary-400;
		}
		
		&--input-wrapper {
			padding: 15px;
			height: 158px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			
			input[type='file'] {
				position: absolute;
				z-index: 1;
				top: 70%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 158px;
				height: 40px;
				opacity: 0;
				cursor: pointer;
			}
			
			label {
				margin-bottom: 20px;
			}
		}
	}
	
	&__file-btn {
		background-color: $primary-400;
		cursor: pointer;
		border-radius: 8px;
		color: $white-100;
		font-size: 14px;
		font-weight: 600;
		padding-top: 9px;
		text-align: center;
		position: absolute;
		top: 70%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 158px;
		height: 40px;
		transition: opacity 0.3s;
		
		&:hover {
			opacity: 0.5;
		}
	}
	
	&__remove-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		
		div:nth-child(2) {
			margin-left: 10px;
			margin-top: 3px;
			cursor: pointer;
			transition: opacity 0.3s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__drop-text {
		font-size: 12px;
		color: $grey-300;
	}
	
	&__bg-green-300 {
		background-color: $green-300;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__bg-gray-100 {
		background-color: $grey-200;
		padding: 20px;
		border-radius: 12px;
		
		@include media-breakpoint-up(md) {
			padding: 30px;
		}
	}
	
	&__error-message {
		color: $secondary-550;
	}
	
	&:deep(.v-expansion-panel-title__icon) {
		color: $primary-400;
	}
	
	&__article-btn-wrapper {
		margin-top: 40px;
		display: flex;
		flex-direction: column;
		gap: 10px;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
		}
	}
	
	&__article-btn-second-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		
		@include media-breakpoint-up(md) {
			flex-direction: row;
			flex-wrap: wrap;
			margin-left: auto;
		}
	}
	
	&__article-menu-wrapper {
		display: flex;
		flex-wrap: wrap;
		gap: 24px;
		
		&:deep(.m-btn__icon-margin) {
			svg {
				margin-top: 7px;
			}
		}
	}
	
	&__article-menu-item {
		font-size: 14px;
		font-weight: 700;
		color: $primary-400;
		padding: 10px 10px 5px;
		cursor: pointer;
		transition: background-color 0.3s;
		
		&:hover {
			background-color: $grey-200;
			border-radius: 8px;
		}
	}
	
	&__article-title {
		margin-top: 24px;
		margin-bottom: 40px;
		color: $primary-400;
		font-size: 32px;
	}
	
	&__publication {
		&:deep(.m-btn) {
			padding: 25px 45px !important;
		}
	}
	
	&__quick-accept-btn-wrapper {
		display: flex;
		flex-direction: column;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			width: 30%;
			margin-left: 20px;
		}
		
		@include media-breakpoint-up(xl) {
			width: 290px;
			margin-left: 40px;
		}
		
		@include media-breakpoint-up(xxl) {
			margin-left: 60px;
		}
	}
	
	&__cancel-btn-wrapper {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
		
		&:deep(.m-btn) {
			border-color: $secondary-400;
			color: $secondary-400 !important;
		}
	}
	
	&__label-required {
		font-size: 14px;
		color: $primary-400;
		font-weight: 500;
		margin-bottom: 16px;
		
		span {
			color: $secondary-400;
		}
	}
	
	&__anchor_wrapper {
		display: flex;
		justify-content: flex-end;
	}
	
	&__photo-wrapper {
		width: max-content;
		max-width: 100%;
		margin-top: 10px;
		position: relative;
		transition: 0.3s;
		
		@include media-breakpoint-up(lg) {
			max-width: 100%;
		}
		
		&:hover {
			.order__photo-btn-wrapper {
				display: flex;
			}
		}
		
		img {
			width: 100%;
			transition: opacity 0.5s;
			
			&:hover {
				opacity: 0.5;
			}
		}
	}
	
	&__photo-btn-wrapper {
		display: none;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		
		&:deep(.v-text-field>.v-input__control>.v-input__slot) {
			cursor: pointer;
		}
	}
	
	&__edit-photo {
		position: relative;
	}
	
	&__btn {
		background-color: $primary-400;
		font-size: 16px;
		padding: 10px;
		min-width: 80px;
		text-align: center;
		border-radius: 8px;
		color: $white-100;
		font-weight: 700;
		cursor: pointer;
		transition: opacity .3s;
		
		&:hover {
			opacity: 0.5;
		}
		
		&--red {
			margin-left: 10px;
			background-color: $secondary-400;
		}
	}
	
	&__edit-btn-wrapper {
		position: relative;
		cursor: pointer;
	}
	
	&__upload-hover-wrapper {
		position: absolute;
		width: 100%;
		top: 0;
		height: 44px;
		cursor: pointer;
		opacity: 0;
		
		input {
			cursor: pointer;
		}
		
		&:deep(.v-input__prepend) {
			width: 0;
		}
		
		&:deep(.v-input__control) {
			width: 100%;
		}
		
		&:deep(.v-input__details) {
			display: none;
		}
	}
	
	&__image-preview {
		margin-top: 24px;
	}
	
	&__reject-wrapper {
		display: flex;
		flex-direction: column;
		gap: 10px;
		align-items: center;
		font-size: 18px;
		font-weight: 700;
	}
	
	&__width-100-percent {
		width: 100%;
	}
	
	&__btn-wrapper {
		margin-top: 20px;
		display: flex;
		gap: 10px;
	}
	
	&__edit-mode-wrapper {
		display: flex;
		justify-content: flex-end;
	}
	
	&__origin-status {
		font-size: 14px;
		color: $primary-400;
	}
	
	&:deep(.m-input__label) {
		color: $primary-400;
		font-weight: 500;
		margin-bottom: 16px;
	}
	
	&:deep(.m-text-area__label) {
		color: $primary-400;
		font-weight: 500;
		margin-bottom: 16px;
	}
	
	&:deep(.m-vue-editor__label) {
		color: $primary-400;
		font-weight: 500;
		margin-bottom: 16px;
	}
}
</style>
