import BaseApi from '@/api/BaseApi';
import type { IChangeStatusRequest, IOrderGroupDetails, IOrderGroupTable, IOrderStats } from '@/models/OrderModel';

export default class OrderApi extends BaseApi {
	async orderGroupList(page: number, perPage: number, sortBy?: string|null, sortDesc?: string|null, search?: string|null, campaign?: string|null, userUuid?: string|null, to?:string|null, from?:string|null, languageId?:number|null, orderType?:string|null, statuses?:string[]|null, isPublisherOrModerator?:boolean, isOutdatedOrders?:boolean, isDashboardView?:boolean, searchAdvertiser?:string | null, searchPublisher?:string | null) {
		let url = `api/v2/orders?page=${page}&per_page=${perPage}`;

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		} else if (isPublisherOrModerator) {
			url += '&sort=publication_started_at';
		} else {
			url += '&sort=updated_at';
		}

		if (null != sortDesc) {
			url += `&order=${sortDesc}`;
		} else {
			url += '&order=desc';
		}

		if (null != search) {
			url += `&search=${search}`;
		}

		if (null != campaign && 'null' !== campaign) {
			url += `&campaign_uuid=${campaign}`;
		}

		if ('null' === campaign) {
			url += '&campaign_uuid=default_campaign_uuid';
		}

		if (null != userUuid) {
			url += `&user_uuid=${userUuid}`;
		}

		if (null != to) {
			url += `&date_to=${to}`;
		}

		if (null != from) {
			url += `&date_from=${from}`;
		}

		if (null != languageId) {
			url += `&language_id=${languageId}`;
		}

		if (null != orderType) {
			url += `&type=${orderType}`;
		}

		if (null != statuses) {
			statuses.forEach((status) => {
				url += `&statuses[]=${status}`;
			});
		}

		if (isOutdatedOrders) {
			url += '&forgotten=1';
		}

		if (isDashboardView) {
			url += '&preview=1';
		}

		if (null != searchAdvertiser) {
			url += `&advertiser=${searchAdvertiser}`;
		}

		if (null != searchPublisher) {
			url += `&publisher=${searchPublisher}`;
		}
		return this.get<IOrderGroupTable>({
			url: url,
		});
	};

	async itemGroupDetails(orderGroupUuid:string|Array<string>) {
		return this.get<IOrderGroupDetails>({
			url: `api/v2/orders/${orderGroupUuid}`,
		});
	};

	async changeOrderStatus(orderGroupUuid:string|Array<string>, params:IChangeStatusRequest) {
		return this.put<IChangeStatusRequest, boolean>({
			url: `api/v2/orders/${orderGroupUuid}`,
			data: params,
		});
	};

	async orderStats(dateTo: string, dateFrom: string) {
		return this.get<IOrderStats>({
			url: `api/v2/orders/stats?date_from=${dateFrom}&date_to=${dateTo}`,
		});
	};

	async forgottenOrderGroupList(page: number, perPage: number, sortBy?: string|null, sortDesc?: string|null) {
		let url = `api/v2/orders/forgotten?page=${page}&per_page=${perPage}`;

		if (null != sortBy) {
			url += `&sort=${sortBy}`;
		} else {
			url += '&sort=publication_started_at';
		}

		if (null != sortDesc) {
			url += `&order=${sortDesc}`;
		} else {
			url += '&order=desc';
		}
		return this.get<IOrderGroupTable>({
			url: url,
		});
	};
};
