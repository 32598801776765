<template>
	<div class="order-list-summary">
		<div class="order-list-summary__title-wrapper">
			<DatePickerWithMenu :max-date="todayDate" is-big-blue-title @emitDates="emitDates" />
		</div>
		<div v-if="null != user && isAdvertiser(user)" class="order-list-summary__wrapper">
			<div class="order-list-summary__box-wrapper">
				<MProgressBar v-show="loading" indeterminate height="2" class="order-list-summary__loader" />
				<div class="order-list-summary__box-text">{{ $t('order.orderFlow.numberOrderedPublications') }}</div>
				<div class="order-list-summary__box-number">{{ orderNumber }}</div>
			</div>
			<div class="order-list-summary__box-wrapper">
				<MProgressBar v-show="loading" indeterminate height="2" class="order-list-summary__loader" />
				<div class="order-list-summary__box-text">{{ $t('order.orderFlow.totalExpenditure') }}</div>
				<div class="order-list-summary__box-number">{{ totalExpenditure }} {{ userCurrencySymbol }}</div>
			</div>
			<div class="order-list-summary__box-wrapper">
				<MProgressBar v-show="loading" indeterminate height="2" class="order-list-summary__loader" />
				<div class="order-list-summary__box-text">{{ $t('order.orderFlow.averageOrderPrice') }}</div>
				<div class="order-list-summary__box-number">{{ averageOrderPrice }} {{ userCurrencySymbol }}</div>
			</div>
		</div>
	</div>
</template>

<script setup lang="ts">
import { ref, defineEmits } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStore } from '@/stores/user';
import { useI18n } from 'vue-i18n';
import { toast } from 'vue3-toastify';
import { isAdvertiser } from '@/hooks/UserHooks';
import OrderApi from '@/api/v2/OrderApi';
import dayjs from 'dayjs';
import DatePickerWithMenu from '@/components/molecules/DatePickerWithMenu.vue';
import MProgressBar from '@/components/atoms/MProgressBar.vue';

const orderApi = new OrderApi();

const emit = defineEmits(['emitDates']);
const { t } = useI18n();
const { user, userCurrencySymbol } = storeToRefs(useUserStore());

const todayDate = ref<string>(dayjs().format('YYYY-MM-DD'));
const orderNumber = ref<number|null>(0);
const totalExpenditure = ref<number|null>(0);
const averageOrderPrice = ref<number|null>(0);
const loading = ref<boolean>(false);

const emitDates = (to:string, from:string) => {
	emit('emitDates', to, from);
	if (null != user.value && isAdvertiser(user.value)) {
		orderStats(to, from);
	}
};

const orderStats = async(to:string, from:string) => {
	loading.value = true;
	try {
		const result = await orderApi.orderStats(to, from);
		if (!result.isSuccess) {
			toast.error(`${t('order.orderFlow.toast.errorOrderStats')}`);
			loading.value = false;
			return;
		}
		orderNumber.value = result.payload.publications_count;
		totalExpenditure.value = null != result.payload.outcomes_total ? +(+result.payload.outcomes_total / 100).toFixed(2) : 0.00;
		averageOrderPrice.value = null != result.payload.outcomes_average_calculated ? +(+result.payload.outcomes_average_calculated / 100).toFixed(2) : 0.00;
	} catch (e) {
		toast.error(`${t('order.orderFlow.toast.errorOrderStats')}`);
		loading.value = false;
		return;
	}
	loading.value = false;
};
</script>

<style scoped lang="scss">
.order-list-summary {
	&__title-wrapper {
		background-color: $grey-150;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 10px;
	}
	
	&__wrapper {
		display: flex;
		flex-wrap: wrap;
		
		@include media-breakpoint-up(md) {
			flex-wrap: nowrap;
			justify-content: space-between;
		}
	}
	
	&__box-wrapper {
		position: relative;
		background-color: $white-100;
		box-shadow: 8px 8px 15px rgba($black, .1);
		padding: 16px 24px;
		border-radius: 6px;
		margin-top: 15px;
		width: 100%;
		text-align: center;
		
		@include media-breakpoint-up(md) {
			text-align: left;
			width: 30%;
		}
	}
	
	&__box-text {
		font-size: 12px;
		color: $primary-400;
	}
	
	&__box-number {
		font-size: 24px;
		color: $primary-400;
		font-weight: 700;
	}
	
	&__loader {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
	}
}
</style>
